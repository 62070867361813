import React from 'react';
import {
    PieChart,
    Pie,
    ResponsiveContainer,
    Legend,
    Cell,
    Tooltip,
} from "recharts";
import "./DonutChart.css";
import CustomTooltip from "./CustomToolTip";

function DonutChart({ upXlmatch, xlmatch, lgmatch, uplgmatch, mdmatch, smmatch, xsmatch }) {
    const staticData = [
        { name: "Accepted", value: 80 },
        { name: "Cancelled", value: 15 },
        { name: "Rejected", value: 5 }
    ];

    const data1 = [
        { name: "Accepted", value: 100 },
        { name: "Cancelled", value: 100 },
        { name: "Rejected", value: 100 },
    ];

    const totalPercentage = staticData.reduce((total, dataPoint) => total + dataPoint.value, 0);

    const COLORS = totalPercentage ? ["#13801C", "#FEC807", "#EA4335"] : ["#646464", "#646464", "#646464"];
    const LEGEND_COLORS =["#13801C", "#FEC807", "#EA4335"];

    const renderLegendText = (value, entry) => {
        return <span style={{ color: "black", marginLeft: "0px" }}>{value}</span>;
    };

    const renderLegendText1 = (value, entry, index) => {
        return <span style={{ color: LEGEND_COLORS[index % LEGEND_COLORS.length], marginLeft: "0px" }}>{value}</span>;
    };

    const CustomLegend = (props) => {
        const { payload } = props;
        return (
            <ul style={{ listStyleType: "none", padding: 0, margin: 0, textAlign: 'center', display: "flex", justifyContent: "space-around", flexWrap: "wrap" }}>
                {payload.map((entry, index) => (
                    <li key={`item-${index}`} style={{ display: "flex", alignItems: "center", marginBottom: 4 }}>
                        <div style={{
                            width: lgmatch ? 30 : 45.92,
                            height: lgmatch ? 14 : 24.17,
                            borderRadius: 3,
                            backgroundColor: LEGEND_COLORS[index % LEGEND_COLORS.length],
                            marginRight: 8
                        }} />
                        <span style={{
                            color: "black",
                            fontFamily: "Inter",
                            fontWeight: '700',
                            fontSize: upXlmatch ? "16px" : xlmatch ? (lgmatch ? (mdmatch ? (smmatch ? (xsmatch ? "12px" : "12px") : "13px") : "14px") : "15px") : "16px"
                        }}>
                            {entry.value}
                        </span>
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <div className='testing-container' style={{ height: "100%" }}>
            {totalPercentage ? (
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart className="donutChart">
                        <Pie
                            data={staticData}
                            dataKey="value"
                            innerRadius={mdmatch || smmatch || xsmatch || lgmatch ? 50 : 70}
                            outerRadius={mdmatch || smmatch || xsmatch || lgmatch ? 70 : 100}
                            fill="#82ca9d"
                            label={false}
                        >
                            {staticData.map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={COLORS[index % COLORS.length]}
                                    stroke="none"
                                    strokeWidth={0}
                                />
                            ))}
                        </Pie>
                        <Legend content={CustomLegend} />
                        <Tooltip content={<CustomTooltip totalPercentage={totalPercentage} />} />
                    </PieChart>
                </ResponsiveContainer>
            ) : (
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart className="donutChart">
                        <Pie
                            data={data1}
                            dataKey="value"
                            innerRadius={mdmatch || smmatch || xsmatch || lgmatch ? 50 : 70}
                            outerRadius={mdmatch || smmatch || xsmatch || lgmatch ? 70 : 100}
                            fill="#82ca9d"
                            label={false}
                        >
                            {data1.map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={COLORS[index % COLORS.length]}
                                    stroke="none"
                                    strokeWidth={0}
                                />
                            ))}
                        </Pie>
                        <Legend content={CustomLegend} />
                    </PieChart>
                </ResponsiveContainer>
            )}
        </div>
    );
}

export default DonutChart;
