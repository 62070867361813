// import { ApolloClient, InMemoryCache } from '@apollo/client';

// const client = new ApolloClient({
//   uri: 'http://159.89.173.195:5000/marketingportal',
//   cache: new InMemoryCache(),
// });

// export default client;

// import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
// import { setContext } from '@apollo/client/link/context';
// import { ApolloLink } from '@apollo/client';
// import { createUploadLink } from 'apollo-upload-client';
// // Create an http link:
// const httpLink = createHttpLink({    
//   uri: 'http://159.89.173.195:5000/marketingportal', // Replace with your GraphQL endpoint
// });


// const uploadLink = createUploadLink({
//   uri: 'http://159.89.173.195:5000/marketingportal', // Your GraphQL endpoint
// });

// // Create an auth link:
// const authLink = setContext((_, { headers }) => {
//   // Retrieve the token from local storage or any other source
//   const token = localStorage.getItem('authToken');

//   // Return the headers to the context:
//   return {
//     headers: {
//       ...headers,
//       authorization: token ? `Bearer ${token}` : '',
//     }
//   }
// });

// // Combine the auth link and http link
// const link = ApolloLink.from([authLink, httpLink,uploadLink]);

// // Create an Apollo Client:
// const client = new ApolloClient({
//   link,
//   cache: new InMemoryCache(),
// });

// export default client;
import { ApolloClient, InMemoryCache, createHttpLink} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ApolloLink } from '@apollo/client';
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";


// Create an upload link for file uploads:
const uploadLink = createUploadLink({
  uri: 'http://159.89.173.195:5000/marketingportal', // Your GraphQL endpoint
});

// Create an auth link for adding the authorization header:
const authLink = setContext((_, { headers }) => {
  // Retrieve the token from local storage or any other source
  const token = localStorage.getItem('authToken');

  // Return the headers with the authorization token:
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    }
  };
});

// Combine the auth link and the upload link
const link = ApolloLink.from([authLink, uploadLink]);

// Create the Apollo Client:
const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

export default client;
