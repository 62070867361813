import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
//import './MyChartComponent.css';

const MyChartComponent = ({ upXlmatch, xlmatch, lgmatch, mdmatch, smmatch, xsmatch, uplgmatch }) => {
    const staticPastWeekOrderDetails = {
        Sunday: 10,
        Monday: 20,
        Tuesday: 30,
        Wednesday: 40,
        Thursday: 50,
        Friday: 60,
        Saturday: 70
    };

    const [data, setData] = useState(staticPastWeekOrderDetails);

    const maxYValue = Math.max(...Object.values(data)) || 1;

    const percentageData = [
        { x: 'Sun', y: (data.Sunday / maxYValue) * 100 },
        { x: 'Mon', y: (data.Monday / maxYValue) * 100 },
        { x: 'Tue', y: (data.Tuesday / maxYValue) * 100 },
        { x: 'Wed', y: (data.Wednesday / maxYValue) * 100 },
        { x: 'Thu', y: (data.Thursday / maxYValue) * 100 },
        { x: 'Fri', y: (data.Friday / maxYValue) * 100 },
        { x: 'Sat', y: (data.Saturday / maxYValue) * 100 }
    ];

    const columnWidth = uplgmatch ? '40%' : '40%';
    const borderRadius = upXlmatch ? 13 : xlmatch ? (lgmatch ? (mdmatch ? (smmatch ? (xsmatch ? 5 : 5) : 5) : 5) : 9) : 10;

    const [chartOptions, setChartOptions] = useState({
        series: [
            {
                name: 'Actual',
                data: percentageData
            }
        ],
        chart: {
            toolbar: {
                show: false,
            },
            id: 'chart',
            type: 'bar',
            offsetY: 0,
            offsetX: 0,
        },
        plotOptions: {
            bar: {
                columnWidth: columnWidth,
                borderRadius: borderRadius,
            }
        },
        colors: ['#13801C'],
        dataLabels: {
            enabled: false
        },
        grid: {
            show: false,
            xaxis: {
                lines: {
                    show: false
                },
            },
            yaxis: {
                lines: {
                    show: false,
                }
            },
        },
        yaxis: {
            min: 0,
            max: 100,
            tickAmount: 4,
            stepSize: 25,
            labels: {
                formatter: function (value) {
                    return value;
                }
            }
        },
        xaxis: {
            show: false,
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
        },
        tooltip: {
            enabled: false,
        }
    });

    useEffect(() => {
        setChartOptions(prevOptions => ({
            ...prevOptions,
            series: [
                {
                    name: 'Actual',
                    data: percentageData
                }
            ]
        }));
    }, [data]);

    return (
        <div id="chart" style={{ width: "100%", height: "100%" }}>
            <Chart options={chartOptions} series={chartOptions.series} type="bar" height="100%" width="100%" />
        </div>
    );
};

export default MyChartComponent;
