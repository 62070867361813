// import { TextField, Typography } from "@mui/material";
// import React, { useState } from "react";
// import { contentFontSize_16 } from "../Typography/index";
// //import CustomTextField from "../Commoncomponents/input"
// import Radio from "@mui/material/Radio";
// import RadioGroup from "@mui/material/RadioGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import FormControl from "@mui/material/FormControl";
// import FormLabel from "@mui/material/FormLabel";
// import Plus from "../../Utils/Images/Plus.svg";
// import CustomButton from "../Commoncomponents/button/index";
// import TemplateElements from "./TemplateElements";
// import { useNavigate } from "react-router-dom";
// function TemplateForm() {
// const [text, setText] = useState("");

// function handleText(event) {
//   setMedia("");
//   setText(event.target.value);
//   console.log("text", text);
// }
// const [media, setMedia] = useState("");
// function handleMedia(event) {
//   setText("");
//   setMedia(event.target.value);
// }
// const [next, setNext] = useState(false);
// const nav = useNavigate();
// function handleNext() {
//   //nav("/template/templateElements")
//   setNext(true);
//   console.log("next", next);
// }
//   return (
//     <>
// <div>
//   {!next ? (
//     <>
//       <div style={{ marginTop: "80px",padding:"20px" }}>
//         <Typography
//           variant="subtitle1"
//           gutterBottom
//           sx={{
//             fontFamily: "Roboto",
//             fontWeight: "600",
//             fontSize: { contentFontSize_16 },
//           }}
//         >
//           Template Name
//         </Typography>
//         <TextField variant="outlined" fullWidth size="normal" />
//       </div>
//       <div style={{ marginTop: "10px",padding:"20px" }}>
//         <Typography
//           variant="subtitle1"
//           gutterBottom
//           sx={{
//             fontFamily: "Roboto",
//             fontWeight: "600",
//             fontSize: { contentFontSize_16 },
//           }}
//         >
//           Language
//         </Typography>
//         <TextField
//           //placeholder="Enter Your Password"
//           type="password"
//           variant="outlined"
//           fullWidth
//         />
//       </div>
//       <div style={{ marginTop: "20px",padding:"20px" }}>
//         <FormControl>
//           <FormLabel
//             id="demo-row-radio-buttons-group-label"
//             sx={{
//               fontFamily: "Roboto",
//               fontWeight: "600",
//               fontSize: { contentFontSize_16 },
//               color: "black !important",
//             }}
//           >
//             Message Header
//           </FormLabel>
//           <RadioGroup
//             row
//             aria-labelledby="demo-row-radio-buttons-group-label"
//             name="row-radio-buttons-group"
//           >
//             <FormControlLabel
//               value="text"
//               control={<Radio />}
//               label="Text"
//               onChange={handleText}
//             />
//             <FormControlLabel
//               value="media"
//               control={<Radio />}
//               label="media"
//               onChange={handleMedia}
//             />
//           </RadioGroup>
//         </FormControl>
//       </div>
//       {text && (
//         <div style={{padding:"20px"}}>
//           <TextField variant="outlined" fullWidth size="normal" />
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "flex-end",
//               marginTop: "10px",
//             }}
//           >
//             <img src={Plus} />
//             <Typography
//               style={{
//                 textAlign: "right",
//                 color: "#0D75BA",
//                 fontFamily: "Roboto",
//                 fontWeight: "600",
//                 fontSize: { contentFontSize_16 },
//               }}
//             >
//               Add variables
//             </Typography>
//           </div>
//         </div>
//       )}
//       {media && <div>hidden</div>}
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "flex-end",
//           marginTop: "0px",
//           padding:"0px 10px 0px 0px "
//         }}
//       >
//         <CustomButton
//           background="#0D75BA"
//           borderRadius="10px"
//           width="90px"
//           onClick={handleNext}
//         >
//           <Typography sx={{ textTransform: "capitalize" }}>
//             Next
//           </Typography>
//         </CustomButton>
//       </div>
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           marginTop: "0px",
//           padding:"0px 10px 0px 0px "
//         }}
//       >
//         <Typography>Step 1 of 3</Typography>
//       </div>
//     </>
//   ) : (
//     <TemplateElements />
//   )}
// </div>
//     </>
//   );
// }

// export default TemplateForm;

import React, { useDeferredValue, useEffect, useState } from "react";
import {
  Typography,
  Button,
  useMediaQuery,
  useTheme,
  TextField,
  Box,
  Stepper,
  Step,
  StepLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Edit from "../../Utils/Images/Edit.svg";
import Delete from "../../Utils/Images/Delete.svg";
import Plus from "../../Utils/Images/Plus.svg";
import CustomButton from "../Commoncomponents/button/index";
import CustomCard from "../Commoncomponents/card/index";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import CustomDialog from "../Commoncomponents/dialog";
import Food from "../../Utils/Images/Food.svg";
import { useDispatch, useSelector } from "react-redux";
import { setDeleteTemplateSuccess } from "../../redux/slices/marketingSlice";
import { message } from "antd";
import { getTemplateDelete, getTemplateList } from "../../graphql/actions";
import { contentFontSize_16_xs_10 } from "../Typography";
export default function TemplateSave() {
  const theme = useTheme();
  const isLessThan350 = useMediaQuery("(max-width:350px)");
  const smMatch = useMediaQuery(theme.breakpoints.down("sm"));
  const mdMatch = useMediaQuery(theme.breakpoints.down("md"));
  const lgMatch = useMediaQuery(theme.breakpoints.down("lg"));
  const xlMatch = useMediaQuery(theme.breakpoints.up("xl"));
  const [dialogOpen, setDialogOpen] = useState(false);
  const steps = [
    "Create New Template",
    "Add Template Elements",
    "Preview Template",
  ];
  const nav = useNavigate();

  const [text, setText] = useState("");
  const [media, setMedia] = useState("");

  function handleText(event) {
    setMedia("");
    setText(event.target.value);
  }

  function handleMedia(event) {
    setText("");
    setMedia(event.target.value);
  }

  function handleNext() {
    nav("/template/templateElements");
  }

  function handleModalOpen() {
    setDialogOpen(true);
  }

  function handleModalClose() {
    setDialogOpen(false);
  }

  function handleClose() {
    setDialogOpen(false);
  }
  function handlePrevious() {
    nav("/template/templateElements");
  }
  useEffect(()=>{
      getTemplateList()
  },[])
  const dispatch = useDispatch();
  const listData = useSelector((state) => state.marketingSlice?.listData);
  console.log("data", listData?.listTemplates);
  const [deletes, setDeletes] = useState(false);
  const deleteData = useSelector((state) => state.marketingSlice?.deleteData);
  // const [deletes, setDeletes] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null); // For tracking the item to delete

  const handleDialog = (id) => {
    setSelectedItemId(id); // Set the ID of the item you want to delete
    setDeletes(true); // Open the dialog
  };
  function handleClose() {
    setDeletes(false);
  }
  function handleDeletes(id) {
    dispatch(getTemplateDelete(id));

    setDeletes(false);
  }
  const deleteTemplateSuccess = useSelector(
    (state) => state.marketingSlice.deleteTemplateSuccess
  );
  console.log("success", deleteTemplateSuccess);

  useEffect(() => {
    if (deleteTemplateSuccess) {
      setDeletes(false);
      message.success({
        content: "Template Deleted Successfully",
        className: "custom-success-message",
        style: {
          marginTop: "10vh",
          marginLeft: "15vw",
        },
        duration: 1.5,
      });

      setTimeout(() => {
        // Reload the list or perform any other actions
        dispatch(setDeleteTemplateSuccess());
        dispatch(getTemplateList());
        // Reset the success flag after handling it
      }, 1500);
    }
  }, [deleteTemplateSuccess]);

  console.log("deleteData", deleteData);




  const leftSideContent = (
    <div
      style={{
        width: "100%",
        backgroundColor: "white",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        position: "relative",
        zIndex: 0,
        padding: "20px",
        minHeight: "150vh",
      }}
    >
      <Typography
        sx={{
          fontWeight: "600",
          fontFamily: "Inter",
          fontSize: { xs: "14px", sm: "16px", md: "20px", lg: "22px" },
          textAlign: "center",
          marginTop: "20px",
        }}
      >
        Available Template
      </Typography>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          sx={{
            width: smMatch ? "60%" : mdMatch ? "130px" : "300px",
            marginTop: isLessThan350 ? "30px" : "120px",
            textAlign: "center",
            textTransform: "capitalize",
             height:"50px",
            borderRadius:"10px"
          }}
        >
          Create New Template
        </Button>
      </div>
      {listData?.listTemplates?.map((item) => (
  <div
    key={item.id}
    style={{
      display: "flex",
      flexDirection: lgMatch ? "column" : "row",
      justifyContent: mdMatch ? "center" : "space-evenly",
      marginTop: "20px",
      alignItems: "center",
    }}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginLeft: "40px",
      }}
    >
      <>
        <CustomCard width="170px" height="100%" padding="10px 30px 80px 10px">
          <div>
            <Typography>{item?.header?.content}</Typography>
          </div>
          <div>
            <Typography>{item?.body}</Typography>
          </div>
          <div style={{ marginTop: "15px" }}>
            <CustomButton background="#0D75BA">
              <Typography style={{ textTransform: "capitalize" }}>
                {item.button}
              </Typography>
            </CustomButton>
          </div>
          <div style={{ marginTop: "15px" }}>
            <Typography>{item.footer}</Typography>
          </div>
        </CustomCard>

        <Typography style={{ textAlign: "center", marginTop: "10px" }}>
          {item.templateName}
        </Typography>
      </>
    </div>
    <div
      style={{
        marginTop: lgMatch ? "20px" : "0",
        display: "flex",
        alignItems: "center",
      }}
    >
      <img
        src={Edit}
        style={{ cursor: "pointer" }}
        //onClick={() => handleEdit(item.id)} // Pass the id onClick
      />
      <img
        src={Delete}
        style={{ cursor: "pointer", marginLeft: "20px" }}
        onClick={() => handleDialog(item.id)} // Open the dialog with item ID
      />
    </div>
  </div>
))}


{deletes && selectedItemId && (
  <Dialog
  open={deletes}
>
  <DialogContent>
    <div style={{ height: "100%", width: "100%", padding: mdMatch ? "10px" : "15px" }}>
      <div style={{ display: "flex", gap: "10px", flexDirection: "column" }}>
        <Typography fontSize={contentFontSize_16_xs_10} className='chatFontHeebo400Color'>Are you sure you want to delete this Template?</Typography>

        <div style={{ display: "flex", gap: mdMatch ? "10px" : "15px", textAlign: "center", justifyContent: "space-evenly" }}>
          <Typography fontSize={contentFontSize_16_xs_10} onClick={handleClose} className='chatFontHeebo400Color' style={{ cursor: "pointer" }}>Cancel</Typography>
          <Typography fontSize={contentFontSize_16_xs_10}>|</Typography>
          <Typography fontSize={contentFontSize_16_xs_10} onClick={() => handleDeletes(selectedItemId)}  className='chatFontHeebo400Red' style={{ cursor: "pointer" }}>Delete</Typography>
        </div>

      </div>

    </div>
  </DialogContent>
</Dialog>
)}
    </div>
  );

  return (
    <>
      <div >
        <Typography
          sx={{ fontWeight: "600", fontFamily: "Inter", fontSize: "28px" }}
        >
          Template
        </Typography>
      </div>
      <div >
        <div
          style={{
            display: "flex",
            minHeight: "100vh",
            border: "1px solid #ccc",
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 25px 5px",
            borderRadius: "8px",
            overflow: "hidden",
            position: "relative",
            marginTop:"30px"
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "15%",
              left: 0,
              right: 0,
              borderBottom: "1px solid #7C7B7B",
              zIndex: 1,
            }}
          />

          {isLessThan350 ? (
            <>
              <CustomDialog
                open={dialogOpen}
                onClose={handleClose}
                top="20px"
                right="20px"
              >
                <DialogContent>{leftSideContent}</DialogContent>
              </CustomDialog>
            </>
          ) : (
            <div style={{ width: "30%" }}>{leftSideContent}</div>
          )}

          <div style={{ flex: 1, padding: "0px" }}>
            <div style={{ padding: "20px" }}>
              <Box sx={{ width: "100%", marginBottom: "0px" }}>
                <Stepper activeStep={2} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            </div>
            <div style={{ padding: "0px 10px 0px 20px" }}>
              {isLessThan350 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleModalOpen}
                  >
                    Show Templates
                  </Button>
                </div>
              )}
              <>
                <div style={{ marginTop: "50px" }}>
                  <CustomCard width="100%" boxShadow="none">
                    {/* <div style={{display:"flex",justifyContent:"center"}}>
                 <img src={Food}/>
                 </div>
                 <div >
                    <Typography>
                        hi hello how are you
                    </Typography>
                 </div> */}
                    <div className="container">
                      <div className="image-container">
                        <img
                          src={Food}
                          alt="Food"
                          style={{ width: "100px", height: "100px" }}
                        />
                      </div>
                      <div className="content">
                        <h2>Dear ,</h2>
                        <p>
                          felis proin a faucibus a. Odio consectetur volutpat
                          aenean pharetra ornare sagittis viverra urna. Morbi eu
                          aliquet tincidunt in enim non sit. At id eget a sit eu
                          nullam lectus nec proin. Facilisis tortor nibh in ac
                          non. Quisque gravida ipsum sed molestie id. Ultricies
                          at donec ultricies.
                        </p>
                        {/* <a href="https://nearshopz.com/" className="link">https://nearshopz.com/</a> */}
                      </div>
                      <div className="buttons">
                        <button className="interested">Interested</button>
                        <button className="not-interested">
                          Not Interested
                        </button>
                      </div>
                      <div className="footer">Marketing Team Nearshopz</div>
                    </div>
                  </CustomCard>
                </div>
              </>
              <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "200px",
                padding: "0px 10px 0px 0px",
              }}
            >
              <div
                style={{ display: "flex", flexDirection: "row", gap: "10px" }}
              >
                <div>
                  <CustomButton
                    background="#0D75BA"
                    borderRadius="10px"
                    width="90px"
                    onClick={handlePrevious}
                  >
                    <Typography sx={{ textTransform: "capitalize" }}>
                      Previous
                    </Typography>
                  </CustomButton>
                </div>
                <div>
                  <CustomButton
                    background="#0D75BA"
                    borderRadius="10px"
                    width="90px"
                    onClick={handleNext}
                  >
                    <Typography sx={{ textTransform: "capitalize" }}>
                      Save
                    </Typography>
                  </CustomButton>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
