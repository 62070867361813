import React, { useState, useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import {Dialog,DialogContent,Typography} from '../MUIcomponents/index'
import CustomButton from "../Commoncomponents/button";
import { contentFontSize_20, contentFontsize_30, contentFontSize_16, contentFontSize_18, HeaderFontSize_26, contentFontsize_14_xs_10, HeaderNavigationFontSize_12, contentFontsize_14 } from '../Typography'


const defaultSrc =
  "https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg";

const CropperModal = ({file,filePreview,setFile,setFilePreview,setOpen4}) => {
  const [image, setImage] = useState(filePreview);
  const [cropData, setCropData] = useState("#");
  const cropperRef = useRef(null);

  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = () => {
    if (cropperRef.current && cropperRef.current.cropper) {
       
      setFilePreview(cropperRef.current.cropper.getCroppedCanvas().toDataURL());
     setOpen4(false)
    }
  };

  const handleclickclose=()=>{
    setOpen4(false)
  }

  return (


       
    <div>
      <div style={{ width: "100%" }}>
       
        <Cropper
          style={{ height: 400, width: "100%" }}
          initialAspectRatio={1}
          preview=".img-preview"
          src={image}
          ref={cropperRef}
          viewMode={1}
          guides={true}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
        />
      </div>
      <div>
        {/* <div className="box" style={{ width: "50%", float: "right" }}>
          <h1>Preview</h1>
          <div
            className="img-preview"
            style={{ width: "100%", float: "left", height: "300px" }}
          />
        </div> */}
        <div style={{display:"flex",gap:"10px",justifyContent:"center",alignItems:"center",marginTop:"20px"}}> 
            <CustomButton background="gray"> <Typography style={{ color: "white", fontFamily: "Inter", fontWeight: "bold",textTransform:"capitalize" }} fontSize={contentFontsize_14_xs_10} onClick={handleclickclose}>
                                cancel
                                </Typography></CustomButton>
            <CustomButton background="#0D75BA">
                <Typography style={{ color: "white", fontFamily: "Inter", fontWeight: "bold",textTransform:"capitalize" }} fontSize={contentFontsize_14_xs_10} onClick={getCropData}>Confirm</Typography>
           
            </CustomButton>
          
       
        </div>
      </div>
      <br style={{ clear: "both" }} />
    </div>

  );
};

export default CropperModal;
