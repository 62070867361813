import * as yup from 'yup';

export const validationSchema = yup.object({
    storeName:yup.string()
    .required("Store Name is required")
    ,
    mobileNo:yup.string()
    .matches(/^\S*$/, "Space is not allowed")
    //eslint-disable-next-line
    .matches(/^[6-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]*$/, "Please enter valid phone number")
    .min(10, "Please enter 10 digit Mobile number")
    .max(10, "Please enter 10 digit Mobile number")
    .required("Please Enter Mobile number"),
    whatsappNo:yup.string()
    .matches(/^\S*$/, "Space is not allowed")
    //eslint-disable-next-line
    .matches(/^[6-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]*$/, "Please enter valid phone number")
    .min(10, "Please enter 10 digit Mobile number")
    .max(10, "Please enter 10 digit Mobile number")
    .required("Please Enter whatsapp number"),
    altMobileNo:yup.string()
    .matches(/^\S*$/, "Space is not allowed")
    //eslint-disable-next-line
    .matches(/^[6-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]*$/, "Please enter valid phone number")
    .min(10, "Please enter 10 digit Mobile number")
    .max(10, "Please enter 10 digit Mobile number"),
    emailId:yup.string()
    .matches(/^\S*$/, "Space is not allowed")
    //eslint-disable-next-line
    .matches( /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,"Email Address must be valid")
    .trim()
    .email("please enter a valid email"),
})