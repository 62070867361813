

import { gql } from '@apollo/client';

export const GET_STORELIST = gql`
 query{
  listStores{
    id
    storeName
    businessType
    state
    district
    city
    mobileNo
    whatsappNo
    altMobileNo
    emailId
    status
  }
}
`;

export const GET_STORE = gql`
  query GetStoreDetails($storeId: ID!) {
    getStoreDetails(storeId: $storeId) {
       id
      storeName
      businessType
      state
      district
      city
      mobileNo
      whatsappNo
      altMobileNo
      emailId
      status
    }
  }
`;





export const SEARCH_STORES = gql`
  query SearchStores(
    $state: String
    $district: String
    $businessType: [String]
    $city: String
    $storeName: String
    $status: String
  ) {
    searchStores(
      state: $state
      district: $district
      businessType: $businessType
      city: $city
      storeName: $storeName
      status: $status
    ) {
      id
      storeName
      businessType
      state
      district
      city
      mobileNo
      whatsappNo
      altMobileNo
      emailId
      status
    }
  }
`;



export const FILTER_STORES_QUERY = gql`
  query FilterStores($filterName:String, $storeName:String, $state: String, $district: String, $city: String, $businessType: [String]) {
    filterStores(filterName:$filterName,storeName:$storeName,state: $state, district: $district, city: $city, businessType: $businessType) {
      id
      storeName
      businessType
      state
      district
      city
      mobileNo
      whatsappNo
      altMobileNo
      emailId
      status
    }
  }
`;


export const GET_FILTERS = gql`
  query {
    getFilters {
      filterName
      state
      district
      city
      businessType
      storeName
    }
  }
`;

export const GET_FILTER = gql`
  query GetFilters($filterName: String) {
    getFilters(filterName: $filterName) {
      id
      filterName
      state
      district
      city
      businessType
      storeName
    }
  }
`;
export const LIST_TEMPLATES = gql`
  query  {
    listTemplates {
      id
      templateName
      language
      header{
      type
      content
      }
      body
      button
      footer
    }
  }
`;