// import { TextField, Typography } from "@mui/material";
// import React, { useState } from "react";
// import { contentFontSize_16 } from "../Typography/index";
// //import CustomTextField from "../Commoncomponents/input"
// import Radio from "@mui/material/Radio";
// import RadioGroup from "@mui/material/RadioGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import FormControl from "@mui/material/FormControl";
// import FormLabel from "@mui/material/FormLabel";
// import Plus from "../../Utils/Images/Plus.svg";
// import CustomButton from "../Commoncomponents/button/index";
// import TemplateElements from "./TemplateElements";
// import { useNavigate } from "react-router-dom";
// function TemplateForm() {
// const [text, setText] = useState("");

// function handleText(event) {
//   setMedia("");
//   setText(event.target.value);
//   console.log("text", text);
// }
// const [media, setMedia] = useState("");
// function handleMedia(event) {
//   setText("");
//   setMedia(event.target.value);
// }
// const [next, setNext] = useState(false);
// const nav = useNavigate();
// function handleNext() {
//   //nav("/template/templateElements")
//   setNext(true);
//   console.log("next", next);
// }
//   return (
//     <>
// <div>
//   {!next ? (
//     <>
//       <div style={{ marginTop: "80px",padding:"20px" }}>
//         <Typography
//           variant="subtitle1"
//           gutterBottom
//           sx={{
//             fontFamily: "Roboto",
//             fontWeight: "600",
//             fontSize: { contentFontSize_16 },
//           }}
//         >
//           Template Name
//         </Typography>
//         <TextField variant="outlined" fullWidth size="normal" />
//       </div>
//       <div style={{ marginTop: "10px",padding:"20px" }}>
//         <Typography
//           variant="subtitle1"
//           gutterBottom
//           sx={{
//             fontFamily: "Roboto",
//             fontWeight: "600",
//             fontSize: { contentFontSize_16 },
//           }}
//         >
//           Language
//         </Typography>
//         <TextField
//           //placeholder="Enter Your Password"
//           type="password"
//           variant="outlined"
//           fullWidth
//         />
//       </div>
//       <div style={{ marginTop: "20px",padding:"20px" }}>
//         <FormControl>
//           <FormLabel
//             id="demo-row-radio-buttons-group-label"
//             sx={{
//               fontFamily: "Roboto",
//               fontWeight: "600",
//               fontSize: { contentFontSize_16 },
//               color: "black !important",
//             }}
//           >
//             Message Header
//           </FormLabel>
//           <RadioGroup
//             row
//             aria-labelledby="demo-row-radio-buttons-group-label"
//             name="row-radio-buttons-group"
//           >
//             <FormControlLabel
//               value="text"
//               control={<Radio />}
//               label="Text"
//               onChange={handleText}
//             />
//             <FormControlLabel
//               value="media"
//               control={<Radio />}
//               label="media"
//               onChange={handleMedia}
//             />
//           </RadioGroup>
//         </FormControl>
//       </div>
//       {text && (
//         <div style={{padding:"20px"}}>
//           <TextField variant="outlined" fullWidth size="normal" />
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "flex-end",
//               marginTop: "10px",
//             }}
//           >
//             <img src={Plus} />
//             <Typography
//               style={{
//                 textAlign: "right",
//                 color: "#0D75BA",
//                 fontFamily: "Roboto",
//                 fontWeight: "600",
//                 fontSize: { contentFontSize_16 },
//               }}
//             >
//               Add variables
//             </Typography>
//           </div>
//         </div>
//       )}
//       {media && <div>hidden</div>}
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "flex-end",
//           marginTop: "0px",
//           padding:"0px 10px 0px 0px "
//         }}
//       >
//         <CustomButton
//           background="#0D75BA"
//           borderRadius="10px"
//           width="90px"
//           onClick={handleNext}
//         >
//           <Typography sx={{ textTransform: "capitalize" }}>
//             Next
//           </Typography>
//         </CustomButton>
//       </div>
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           marginTop: "0px",
//           padding:"0px 10px 0px 0px "
//         }}
//       >
//         <Typography>Step 1 of 3</Typography>
//       </div>
//     </>
//   ) : (
//     <TemplateElements />
//   )}
// </div>
//     </>
//   );
// }

// export default TemplateForm;

import React, { useEffect, useRef, useState } from "react";
import {
  Typography,
  Button,
  useMediaQuery,
  useTheme,
  TextField,
  Box,
  Stepper,
  Step,
  StepLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  IconButton,
  Dialog,
  DialogContent,
  Popover,
  DialogActions,
  useScrollTrigger,
  MenuItem,
  Menu,
  DialogTitle,
} from "@mui/material";
import Edit from "../../Utils/Images/Edit.svg";
import Delete from "../../Utils/Images/Delete.svg";
import Plus from "../../Utils/Images/Plus.svg";
import CustomButton from "../Commoncomponents/button/index";
import CustomCard from "../Commoncomponents/card/index";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import CustomDialog from "../Commoncomponents/dialog";
import "./TemplateForm.css";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CropperModal from "../Stores/CropperModal";
import ImageIcon from "@mui/icons-material/Image";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import CropIcon from "@mui/icons-material/Crop";
//import CloseIcon from '@mui/icons-material/Close';
import SendIcon from "@mui/icons-material/Send";
import image from "../../Utils/Images/image.svg";
import { contentFontsize_14_xs_10, contentFontSize_16, contentFontSize_16_xs_10 } from "../Typography";
import defaultImage from "../../Utils/Images/defaultImage.svg";
import video from "../../Utils/Images/video.svg";
import defaultVideo from "../../Utils/Images/defaultVideo.svg";
import defaultDocument from "../../Utils/Images/defaultDocument.svg";
import documentss from "../../Utils/Images/documentss.svg";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getImageUpload, GetStoreList, getTemplateDelete, getTemplateList } from "../../graphql/actions";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
//import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { setDeleteTemplateSuccess } from '../../redux/slices/marketingSlice'
export default function TemplateForm() {
  const theme = useTheme();
  const isLessThan350 = useMediaQuery("(max-width:350px)");
  const isLessThan600 = useMediaQuery("(max-width:600px)");
  const smMatch = useMediaQuery(theme.breakpoints.down("sm"));
  const mdMatch = useMediaQuery(theme.breakpoints.down("md"));
  const lgMatch = useMediaQuery(theme.breakpoints.down("lg"));
  const xlMatch = useMediaQuery(theme.breakpoints.up("xl"));
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [dialogOpen, setDialogOpen] = useState(false);
  const steps = [
    "Create New Template",
    "Add Template Elements",
    "Preview Template",
  ];
  const nav = useNavigate();
  const location = useLocation()
  const previous = location?.state?.previous
  console.log("previous",previous)
  const [text, setText] = useState("");
  const [media, setMedia] = useState("");

  function handleText(event) {
    setMedia("");
    setText(event.target.value);
  }

  function handleMedia(event) {
    setText("");
    setMedia(event.target.value);
  }

  // function handleNext() {
  //   nav("/template/templateElements");
  // }

  function handleModalOpen() {
    setDialogOpen(true);
  }

  function handleModalClose() {
    setDialogOpen(false);
  }

  function handleClose() {
    setDialogOpen(false);
    setOpen(false);
    setAnchorEl(null);
    setOpen5(false)
    setDeletes(false)
    setSelectedItemId(null);
  }

  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [open1, setOpen1] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const imageInputRef = useRef(null);
  const videoInputRef = useRef(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const documentInputRef = useRef(null);
  const [cropImage, setCropImage] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      const previewUrl = URL.createObjectURL(selectedFile);
      setFilePreview(previewUrl);
      //console.log("filePreview without blob:", previewUrl.replace("blob:", ""));
    }
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickClose4 = () => {
    setOpen4(false);
  };

  const handleCropImage = () => {
    setCropImage(true);
    setOpen4(true);
  };

  console.log("file", file);
  console.log("filePreview", filePreview);

  const handleClick2 = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl(null);
  };
  const open2 = Boolean(anchorEl);

  // const handleClick3 = (event) => {
  //     setAnchorEl1(event.currentTarget);
  // };
  useEffect(() => {
    if (filePreview) {
      setAnchorEl(null);
      setAnchorEl1(true);
    }
  }, [filePreview]);
  const dispatch = useDispatch();
   const listData = useSelector((state) => state.marketingSlice?.listData);
  console.log("data", listData?.listTemplates);
  const handleClose3 = () => {
    setAnchorEl1(null);
    setFile(null);
    setFilePreview(null);
  };
  //const open3 = Boolean(anchorEl1);
  const toggleDrawer = (newOpen) => () => {
    setOpen1(newOpen);
  };
  const [one, setOne] = useState(false);
  const [two, setTwo] = useState(false);
  const [three, setThree] = useState(false);
  const handleRadio = (event) => {
    // Reset all radio buttons to unselected
    setOne(false);
    setTwo(false);
    setThree(false);

    // Determine which radio button was clicked and trigger the corresponding file input
    if (event.target.id === "image-input") {
      imageInputRef.current.click();
      setOne(true);
    } else if (event.target.id === "video-input") {
      videoInputRef.current.click();
      setTwo(true);
    } else if (event.target.id === "file-input") {
      documentInputRef.current.click();
      setThree(true);
    }
  };

  const open3 = Boolean(anchorEl1);
  // const toggleDrawer = (newOpen) => () => {
  //     setOpen1(newOpen);
  // };
  const deleteError = useSelector(state => state.marketingSlice?.deleteError);
  const deleteData = useSelector(state => state.marketingSlice?.deleteData);
  const [deletes, setDeletes] = useState(false)
  const [selectedItemId, setSelectedItemId] = useState(null); // For tracking the item to delete

const handleDialog = (id) => {
  setSelectedItemId(id); // Set the ID of the item you want to delete
  setDeletes(true); // Open the dialog
};
  function handleDelete(id) {
   
    dispatch(getTemplateDelete(id))
      // .then(() => {
      //   dispatch(getTemplateList());
      //   if (deleteData.deleteTemplate) {
      //     setTimeout(() => {
      //       window.location.reload(); // Use window.location.reload() to reload the page
      //     }, 1000);
      //   }
      // })
      // .catch(error => {
      //   console.error("Error deleting template: ", error);
      // });
      setDeletes(false)
  }
  const deleteTemplateSuccess = useSelector(state => state.marketingSlice.deleteTemplateSuccess);
  console.log("success", deleteTemplateSuccess);
  
  useEffect(() => {
    if (deleteTemplateSuccess) {
      setDeletes(false);
      message.success({
        content: "Template Deleted Successfully",
        className: "custom-success-message",
        style: {
          marginTop: "10vh",
          marginLeft: "15vw",
        },
        duration: 1.5,
      });
      
      setTimeout(() => {
        // Reload the list or perform any other actions
        dispatch(setDeleteTemplateSuccess());
        dispatch(getTemplateList());
        // Reset the success flag after handling it
      }, 1500);
    }
  }, [deleteTemplateSuccess]);
  
  console.log("deleteData",deleteData)

  // useEffect(() => {
  //   if (!Formik.isSubmitting) return;
  //   if (Object.keys(Formik.errors).length > 0) {
  //     document.getElementsByName(Object.keys(Formik.errors)[0])[0].focus();
  //   }
  // }, [Formik]);
  //const deleteListTemplateSuccess = useSelector(state => state.marketingSlice?.deleteListTemplateSuccess);

  // useEffect(() => {
  //   if (deleteListTemplateSuccess) {
  //     // Immediately reset the success flag to prevent further execution
  //     dispatch(setDeleteListTemplateSuccess());  // Reset deleteListTemplateSuccess to false
      
  //     setDeletes(false);  // Close the delete dialog or reset the state
  
  //     message.success({
  //       content: "Template Deleted Successfully",
  //       className: "custom-success-message",
  //       style: {
  //         marginTop: "10vh",
  //         marginLeft: "15vw",
  //       },
  //       duration: 1.5,
  //     });
  
  //     setTimeout(() => {
  //       dispatch(getTemplateList());  // Re-fetch the template list after 1.5s
  //     }, 1500);
  //   }
  // }, [deleteListTemplateSuccess]);
  
  useEffect(() => {
    dispatch(getTemplateList());
  }, []);
 

  // useEffect(() => {
  //   dispatch(getTemplateList());
  // }, [deleteData]);


  const [open, setOpen] = useState(false);
  const [templateDetails, setTemplateDetails] = useState("");
  const handleEdit = (id) => {
    setOpen(true);
    // Find the item in the listTemplates array with the matching id

    const itemDetails = listData?.listTemplates?.find((item) => item.id === id);
    setTemplateDetails(itemDetails);
    if (itemDetails) {
      // Proceed with the edit logic using the found item details
      console.log("Editing item:", itemDetails);
      // You can now use itemDetails to populate the form for editing or any other logic
    } else {
      // Handle the case where the item is not found
      console.log(`Item with id: ${id} not found`);
    }
  };
  const [header, setHeader] = useState("");
  const [body, setBody] = useState("");
  const [button, setButton] = useState("");
  const [footer, setFooter] = useState("");
  useEffect(() => {
    // Initialize the header with the existing value if it exists
    if (templateDetails) {
      setHeader(templateDetails.header.content);
      setBody(templateDetails.body);
      setButton(templateDetails.button);
      setFooter(templateDetails.footer);
    }
  }, [templateDetails]);

  function handleHeader(event) {
    const newValue = event.target.value;
    setHeader(newValue);
    console.log("header", newValue); // Log the updated value
  }

  function handlebody(event) {
    const newBody = event.target.value;
    setBody(newBody);
    console.log("body", newBody);
  }

  function handleButton(event) {
    const newButton = event.target.value;
    setButton(newButton);
    console.log("button", newButton);
  }
 
  function handleFooter(event) {
    const newFooter = event.target.value;
    setFooter(newFooter);
    console.log("footer", newFooter);
  }
  //  function handleAdd(){
  //   dispatch(
  //     getEditTemplate(
  //       templateName,
  //       language,
  //       headerType,
  //       headerContent,
  //       body,
  //       button,
  //       footer
  //     )
  //   );
  //  }
  const [open5,setOpen5] = useState(false)
  function handleClickOpen(){
    setOpen5(true)
  }
  const [selectedOption, setSelectedOption] = useState("");
  const handleSelect = (option) => {
    setSelectedOption(option); // Save the selected option in the state
    setAnchorEl(null); // Close the dropdown
  };

  const [images, setImage] = useState(false);
  console.log("preview", filePreview);
  const leftSideContent = (
    <div
      style={{
        width: "100%",
        backgroundColor: "white",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        position: "relative",
        zIndex: 0,
        padding: "20px",
        minHeight: "150vh",
      }}
    >
      <Typography
        sx={{
          fontWeight: "600",
          fontFamily: "Inter",
          fontSize: { xs: "14px", sm: "16px", md: "20px", lg: "22px" },
          textAlign: "center",
          marginTop: "20px",
        }}
      >
        Available Template
      </Typography>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          sx={{
            width: smMatch ? "60%" : mdMatch ? "130px" : "300px",
            marginTop: isLessThan350 ? "30px" : "120px",
            textAlign: "center",
            textTransform: "capitalize",
            height: "50px",
            borderRadius: "10px",
          }}
        >
          Create New Template
        </Button>
      </div>
      {/* {listData?.listTemplates?.map((item) => (
        <div
          key={item.id}
          style={{
            display: "flex",
            flexDirection: lgMatch ? "column" : "row",
            justifyContent: mdMatch ? "center" : "space-evenly",
            marginTop: "20px",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginLeft: "40px",
            }}
          >
            <>
              <CustomCard
                width="170px"
                height="100%"
                padding="10px 30px 80px 10px"
              >
                <div>
                  <Typography>{item?.header?.content}</Typography>
                </div>
                <div>
                  <Typography>{item?.body}</Typography>
                </div>
                <div style={{ marginTop: "15px" }}>
                  <CustomButton background="#0D75BA">
                    <Typography style={{ textTransform: "capitalize" }}>
                      {item.button}
                    </Typography>
                  </CustomButton>
                </div>
                <div style={{ marginTop: "15px" }}>
                  <Typography>{item.footer}</Typography>
                </div>
              </CustomCard>

              <Typography style={{ textAlign: "center", marginTop: "10px" }}>
                {item.templateName}
              </Typography>
            </>
          </div>
          <div
            style={{
              marginTop: lgMatch ? "20px" : "0",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={Edit}
              style={{ cursor: "pointer" }}
              onClick={() => handleEdit(item.id)} // Pass the id onClick
            />
            <img
              src={Delete}
              style={{ cursor: "pointer", marginLeft: "20px" }}
               onClick={handleDialog}
            />
          </div>
          <CustomDialog
  open={deletes}
  onClose={handleClose}
  top="20px"
  right="20px"
  width="400px"
  height="600px"
  borderRadius="25px"
  BackdropProps={{
    style: {
      backgroundColor: "rgba(0, 0, 0, 0.2)", // Adjust the opacity or color here
    },
  }}
>
  <DialogContent>
    <Typography sx={{color:"black",fontWeight:"400",fontSize:"18px"}}>Are you sure you want to delete</Typography>
  </DialogContent>
  <DialogActions>
    <div style={{display:"flex",justifyContent:"center",alignItems:"center",marginRight:"70px",gap:"60px"}}>
      <div>
        <CustomButton onClick={() => handleDelete(item.id)} color="white" background="#0D75BA">
          Yes
        </CustomButton>
      </div>
      <div>
        <CustomButton onClick={handleClose} color="white" background="#0D75BA">
          No
        </CustomButton>
      </div>
    </div>
  </DialogActions>
</CustomDialog>

        </div>
      ))} */}
      {listData?.listTemplates?.map((item) => (
  <div
    key={item.id}
    style={{
      display: "flex",
      flexDirection: lgMatch ? "column" : "row",
      justifyContent: mdMatch ? "center" : "space-evenly",
      marginTop: "20px",
      alignItems: "center",
    }}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginLeft: "40px",
      }}
    >
      <>
        <CustomCard width="170px" height="100%" padding="10px 30px 80px 10px">
          <div>
            <Typography>{item?.header?.content}</Typography>
          </div>
          <div>
            <Typography>{item?.body}</Typography>
          </div>
          <div style={{ marginTop: "15px" }}>
            <CustomButton background="#0D75BA">
              <Typography style={{ textTransform: "capitalize" }}>
                {item.button}
              </Typography>
            </CustomButton>
          </div>
          <div style={{ marginTop: "15px" }}>
            <Typography>{item.footer}</Typography>
          </div>
        </CustomCard>

        <Typography style={{ textAlign: "center", marginTop: "10px" }}>
          {item.templateName}
        </Typography>
      </>
    </div>
    <div
      style={{
        marginTop: lgMatch ? "20px" : "0",
        display: "flex",
        alignItems: "center",
      }}
    >
      <img
        src={Edit}
        style={{ cursor: "pointer" }}
        onClick={() => handleEdit(item.id)} // Pass the id onClick
      />
      <img
        src={Delete}
        style={{ cursor: "pointer", marginLeft: "20px" }}
        onClick={() => handleDialog(item.id)} // Open the dialog with item ID
      />
    </div>
  </div>
))}


{deletes && selectedItemId && (
  <Dialog
  open={deletes}
>
  <DialogContent>
    <div style={{ height: "100%", width: "100%", padding: mdMatch ? "10px" : "15px" }}>
      <div style={{ display: "flex", gap: "10px", flexDirection: "column" }}>
        <Typography fontSize={contentFontSize_16_xs_10} className='chatFontHeebo400Color'>Are you sure you want to delete this Template?</Typography>

        <div style={{ display: "flex", gap: mdMatch ? "10px" : "15px", textAlign: "center", justifyContent: "space-evenly" }}>
          <Typography fontSize={contentFontSize_16_xs_10} onClick={handleClose} className='chatFontHeebo400Color' style={{ cursor: "pointer" }}>Cancel</Typography>
          <Typography fontSize={contentFontSize_16_xs_10}>|</Typography>
          <Typography fontSize={contentFontSize_16_xs_10} onClick={() => handleDelete(selectedItemId)}  className='chatFontHeebo400Red' style={{ cursor: "pointer" }}>Delete</Typography>
        </div>

      </div>

    </div>
  </DialogContent>
</Dialog>
)}

      {open && (
        <CustomDialog
          open={open}
          top="20px"
          right="20px"
          width="90%"
          height="600px"
          onClose={handleClose}
          borderRadius="25px"
        >
          <DialogContent>
            <TextField
              margin="dense"
              label="Message Header"
              type="text"
              fullWidth
              variant="outlined"
              value={header}
              onChange={handleHeader}
            />
            <TextField
              margin="dense"
              label="Body"
              type="text"
              fullWidth
              variant="outlined"
              value={body}
              onChange={handlebody}
            />
            <TextField
              margin="dense"
              label="Button"
              type="text"
              fullWidth
              variant="outlined"
              value={button}
              onChange={handleButton}
            />
            <TextField
              margin="dense"
              label="Footer"
              type="text"
              fullWidth
              variant="outlined"
              value={footer}
              onChange={handleFooter}
            />
          </DialogContent>
          <DialogActions>
            <Box
              sx={{
                display: "flex",
                //flexDirection: isLessThan540 ? "column" : "row",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                gap: "30px",
                //marginRight: smmatch ? "30px" : "80px",
              }}
            >
              <CustomButton
                onClick={handleClose}
                width="170px"
                height="43px"
                borderRadius="15px"
                color="#0D75BA"
                background="white"
                variant="outlined"
                border="5px solid #0D75BA"
                borderColor="#0D75BA"
              >
                <Typography
                  sx={{
                    color: "#0D75BA",
                    textTransform: "capitalize",
                    fontFamily: "Inter",
                    fontWeight: "500",
                  }}
                >
                  Cancel
                </Typography>
              </CustomButton>
              <CustomButton
                //onClick={handleAdd}
                width="170px"
                height="43px"
                borderRadius="15px"
                color="white"
                background="#0D75BA"
                variant="contained"
              >
                <Typography
                  sx={{
                    color: "white",
                    textTransform: "capitalize",
                    fontFamily: "Inter",
                    fontWeight: "500",
                  }}
                >
                  Add
                </Typography>
              </CustomButton>
            </Box>
          </DialogActions>
        </CustomDialog>
      )}
    </div>
  );
  function handleClose1() {
    setAnchorEl1(null);
  }







  
  function handleUpload() {
    console.log("fff",file)
    dispatch(getImageUpload(file))
    setImage(true);
    handleClose1();
    localStorage.setItem("media", filePreview);
  }


  
  const validationSchema = Yup.object({
    templateName: Yup.string(),
    language: Yup.string(),
    messageHeader: Yup.string(),
    messageText: Yup.string(),
  });
  const [next, setNext] = useState(false);
  // const savedValues = localStorage.getItem('formValues');
  //   return savedValues ? JSON.parse(savedValues) : initialValues;
  // const [formValues, setFormValues] = useState(loadSavedValues);

  // useEffect(() => {
  //   setFormValues(loadSavedValues());
  // }, []);
  const initialValues = {
    templateName: '',
    language: '',
    messageHeader: '',
    messageText: ''
  };
  const loadSavedValues = () => {
    const savedValues = localStorage.getItem('formValues');
    return savedValues && !previous ? JSON.parse(savedValues) : initialValues;
  };

  const [formValues, setFormValues] = useState(loadSavedValues);

  useEffect(() => {
  
    setFormValues(loadSavedValues())
  
  }, []);

useEffect(()=>{
 setFormValues("")
 localStorage.removeItem('formValues')
},[])




  const handleNext = (values) => {
    setNext(true);
    console.log("Form Values:", values);
    localStorage.setItem('formValues', JSON.stringify(values));

    // Check if templateName or language is empty or if messageHeader is empty
    if (
      values.templateName === "" ||
      values.language === "" ||
      !values.messageHeader
    ) {
      message.error({
        content: "Please fill in all fields to continue",
        className: "custom-class",
        style: {
          marginTop: "20vh",
          marginLeft: "40vh",
        },
      });
    } else {
      nav("/template/templateElements", {
        state: { value: values, next: next, option: selectedOption },
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   if (!formik.isSubmitting) return;
  //   if (Object.keys(formik.errors).length > 0) {
  //     document.getElementsByName(Object.keys(formik.errors)[0])[0].focus();
  //   }
  // }, [formik]);

  return (
    <>
      <div>
        <Typography
          sx={{
            fontWeight: "600",
            fontFamily: "Inter",
            fontSize: "28px",
            marginBottom: "20px",
          }}
        >
          Template
        </Typography>
      </div>
      <div>
        <div
          style={{
            display: "flex",
            minHeight: "100vh",
            border: "1px solid #ccc",
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 25px 5px",
            borderRadius: "8px",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "150px",
              left: 0,
              right: 0,
              borderBottom: "1px solid #7C7B7B",
              zIndex: 1,
              flexGrow: 0,
              flexShrink: 0,
              maxHeight: "60px", // Set a maximum height
            }}
          />

          {isLessThan350 ? (
            <>
              <CustomDialog
                open={dialogOpen}
                onClose={handleClose}
                top="20px"
                right="20px"
              >
                <DialogContent>{leftSideContent}</DialogContent>
              </CustomDialog>
            </>
          ) : (
            <div style={{ width: "30%" }}>{leftSideContent}</div>
          )}

          <div style={{ flex: 1, padding: "0px" }}>
            <div style={{ padding: "20px" }}>
              <Box sx={{ width: "100%", marginBottom: "0px" }}>
                <Stepper alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            </div>
            <div style={{ padding: "0px 10px 0px 20px" }}>
              {isLessThan350 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleModalOpen}
                  >
                    Show Templates
                  </Button>
                </div>
              )}
              <Formik
                initialValues= {formValues} 
                validationSchema={validationSchema}
                onSubmit={handleNext}
              >
                {({
                  values,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  // isValid,
                  // dirty,
                }) => (
                  <Form>
                    <div style={{ marginTop: "80px" }}>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          fontFamily: "Roboto",
                          fontWeight: "600",
                          fontSize: {
                            xs: "14px",
                            sm: "16px",
                            md: "18px",
                            lg: "20px",
                          },
                        }}
                      >
                        Template Name
                      </Typography>
                      <Field
                        name="templateName"
                        as={TextField}
                        variant="outlined"
                        size="normal"
                        sx={{
                          width: "100%",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "5px",

                            "& fieldset": {
                              border: "1px solid #0D75BA",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#0D75BA",
                            },
                          },
                          "& .MuiInputBase-input": {
                            backgroundColor: "white",
                          },
                        }}
                        helperText={<ErrorMessage name="templateName" />}
                        error={
                          !!(values.templateName && values.templateName.error)
                        }
                      />
                    </div>
                    <div style={{ marginTop: "10px", padding: "0px" }}>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          fontFamily: "Roboto",
                          fontWeight: "600",
                          fontSize: {
                            xs: "14px",
                            sm: "16px",
                            md: "18px",
                            lg: "20px",
                          },
                        }}
                      >
                        Language
                      </Typography>
                      <Field
                        name="language"
                        as={TextField}
                        variant="outlined"
                        sx={{
                          width: "100%",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "5px",
                            "& fieldset": {
                              border: "1px solid #0D75BA",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#0D75BA",
                            },
                          },
                          "& .MuiInputBase-input": {
                            backgroundColor: "white",
                          },
                        }}
                        helperText={<ErrorMessage name="language" />}
                        error={!!(values.language && values.language.error)}
                      />
                    </div>
                    <div style={{ marginTop: "20px", padding: "0px" }}>
                      <FormControl>
                        <FormLabel
                          id="demo-row-radio-buttons-group-label"
                          sx={{
                            fontFamily: "Roboto",
                            fontWeight: "600",
                            fontSize: {
                              xs: "14px",
                              sm: "16px",
                              md: "18px",
                              lg: "20px",
                            },
                            color: "black !important",
                          }}
                        >
                          Message Header
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="messageHeader"
                          value={values.messageHeader}
                          onChange={(event) =>
                            setFieldValue(
                              "messageHeader",
                              event.currentTarget.value
                            )
                          }
                        >
                          <FormControlLabel
                            value="text"
                            control={<Radio />}
                            label="Text"
                            onChange={handleText}
                          />
                          <FormControlLabel
                            value="media"
                            control={<Radio />}
                            label="Media"
                            onChange={handleMedia}
                          />
                        </RadioGroup>
                        <ErrorMessage
                          name="messageHeader"
                          component="div"
                          style={{ color: "red" }}
                        />
                      </FormControl>
                    </div>
                    {values.messageHeader === "text" && (
                      <div style={{ padding: "20px" }}>
                        <Field
                          name="messageText"
                          as={TextField}
                          variant="outlined"
                          fullWidth
                          size="normal"
                          helperText={<ErrorMessage name="messageText" />}
                          error={
                            !!(values.messageText && values.messageText.error)
                          }
                        />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: "10px",
                          }}
                        >
                          <IconButton onClick={handleClick}>
                            <img src={Plus} alt="Add" />
                          </IconButton>
                          <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                          >
                            <MenuItem
                              onClick={() => {
                                handleSelect("Store Name");
                                setFieldValue(
                                  "messageText",
                                  `${values.messageText}Store Name`
                                );
                              }}
                            >
                              Store Name
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                handleSelect("Location");
                                setFieldValue(
                                  "messageText",
                                  `${values.messageText}Location`
                                );
                              }}
                            >
                              Location
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                handleSelect("Owner Name");
                                setFieldValue(
                                  "messageText",
                                  `${values.messageText}Owner Name`
                                );
                              }}
                            >
                              Owner Name
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                handleSelect("Business Type");
                                setFieldValue(
                                  "messageText",
                                  `${values.messageText} Business Type`
                                );
                              }}
                            >
                              Business Type
                            </MenuItem>
                          </Menu>
                          <Typography
                            style={{
                              textAlign: "right",
                              color: "#0D75BA",
                              fontFamily: "Roboto",
                              fontWeight: "600",
                              marginTop: "6px",
                              fontSize: {
                                xs: "14px",
                                sm: "16px",
                                md: "18px",
                                lg: "20px",
                              },
                            }}
                          >
                            Add variables
                          </Typography>
                        </div>
                      </div>
                    )}
                    {values.messageHeader === "media" && (
  <div>
    <CustomCard border="1px solid #0D75BA" boxShadow="none">
      <div className="upload-container">
        <div className="upload-header">
          <p>Please upload a square image (JPEG or PNG) that is less than 100KB in size.</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: isLessThan600 ? "column" : "row",
            gap: "20px",
          }}
        >
          {/* Image Upload */}
          <label className="upload-label">
            <div className="upload-option image-option">
              {one ? <img src={image} alt="Upload" /> : <img src={defaultImage} alt="Default" />}
              <input name="form" type="radio" onChange={handleRadio} accept=".png,.jpeg" id="image-input" />
            </div>
          </label>

          {/* Video Upload */}
          <label className="upload-label">
            <div className="upload-option video-option">
              {two ? <img src={video} alt="Upload" /> : <img src={defaultVideo} alt="Default" />}
              <input name="form" type="radio" accept="video/*" id="video-input" onChange={handleRadio} />
            </div>
          </label>

          {/* Document Upload */}
          <label className="upload-label">
            <div className="upload-option video-option">
              {three ? <img src={documentss} alt="Upload" /> : <img src={defaultDocument} alt="Default" />}
              <input type="radio" name="form" accept="application/pdf/*" id="file-input" onChange={handleRadio} />
            </div>
          </label>
        </div>

        {/* Hidden File Inputs */}
        <input
  type="file"
  name="upload"
  accept=".jpeg, .png,jpg"
  id="image-input"
  onChange={handleFileChange}
  ref={imageInputRef}
  style={{ display: "none" }}
/>

        <input
          type="file"
          name="upload"
          ref={videoInputRef}
          id="video-input"
          accept=".mp4"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        <input
          type="file"
          name="upload"
          ref={documentInputRef}
          id="file-input"
          accept=".pdf"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />

        {/* Preview and Action Buttons */}
        {file && (
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {file.type.startsWith("image/") && image  && (<img src={filePreview} alt="Preview" style={{ width: "80px", height: "80px" }} />)}
            {file.type.startsWith("video/") && (
              <video style={{ width: "80px", height: "80px" }} controls>
                <source src={filePreview} type={file.type} />
              </video>
            )}
            {file.type.startsWith("application/") && (
              <InsertDriveFileIcon style={{ fontSize: "50px" }} />
            )}

            {/* View Button */}
            <IconButton onClick={handleClickOpen}>
        <VisibilityIcon style={{ fontSize: '24px', color: '#0D75BA' }} />
      </IconButton>

      {/* Dialog for showing the image */}
      <Dialog open={open5} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogContent>
          <img src={filePreview} alt="Preview" style={{ width: '100%', height: 'auto' }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

            {/* Delete Button */}
            <IconButton >
              <DeleteIcon style={{ fontSize: "24px", color: "#F44336" }} />
            </IconButton>
          </div>
        )}

        <Dialog
          open={open3}
          onClose={handleClose1}
          PaperProps={{
            style: {
              marginTop: "-30px", // Adjust this value if needed
            },
          }}
          maxWidth="xs" // Adjust the maxWidth as per your needs
          fullWidth
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              padding: "10px 10px 0px 10px",
              position: "relative",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {file && file.type.startsWith("image/") && (
                <>
                  <img src={filePreview} alt="image" width="130px" height="130px" />
                </>
              )}
              {file && file.type.startsWith("video/") && (
                <video controls style={{ width: "200px", height: "200px" }}>
                  <source src={filePreview} type={file.type} />
                </video>
              )}
              {file && file.type.startsWith("application/") && (
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                  <InsertDriveFileIcon style={{ fontSize: "70px" }} />
                  <Typography style={{ marginTop: "10px", textAlign: "center" }} fontSize="16px">
                    {file.name}
                  </Typography>
                  <a href={filePreview} target="_blank" rel="noopener noreferrer" style={{ marginTop: "10px", color: "#0D75BA", fontSize: "14px" }}>
                    Open
                  </a>
                </div>
              )}
              <div style={{ marginTop: "10px", textAlign: "right", width: "100%" }}>
                <CustomButton onClick={handleUpload}>Upload</CustomButton>
              </div>
              <div style={{ margin: "5px" }}>
                <Typography
                  onClick={handleClose1}
                  style={{
                    color: "#0D75BA",
                    fontFamily: "Inter",
                    fontWeight: "bold",
                    cursor: "pointer",
                    position: "absolute",
                    top: "10px",
                    right: "7px",
                  }}
                  fontSize="14px"
                >
                  <CloseIcon sx={{ height: "25px", width: "25px", color: "black" }} />
                </Typography>
              </div>
            </div>
          </div>
        </Dialog>

        {cropImage && (
          <Dialog open={open4} onClose={handleClickClose4}>
            <DialogContent>
              <CropperModal setFile={setFile} setFilePreview={setFilePreview} file={file} filePreview={filePreview} setOpen4={setOpen4} />
            </DialogContent>
          </Dialog>
        )}
      </div>
    </CustomCard>
  </div>
)}

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "150px",
                        padding: "0px 10px 0px 0px",
                        cursor: "pointer",
                      }}
                    >
                      <CustomButton
                        background="#0D75BA"
                        borderRadius="10px"
                        width="90px"
                        type="submit"
                        //disabled={!(dirty && isValid)}
                      >
                        <Typography sx={{ textTransform: "capitalize" }}>
                          Next
                        </Typography>
                      </CustomButton>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "0px",
                        padding: "0px 10px 0px 0px",
                      }}
                    >
                      <Typography>Step 1 of 3</Typography>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
