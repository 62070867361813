import React, { useState, useEffect } from 'react';
import { CssBaseline, Box, Card, CardContent, Typography, TextField, Button, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { getLogin, getToken } from '../../graphql/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { message } from "antd";
import { clearLoginError } from '../../redux/slices/marketingSlice';
import Background from "../../Utils/Images/Background.jpeg"
// Validation schema using Yup
const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email format').required('Email is required'),
  password: Yup.string().required("Password is required"),
});

function Login() {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const initialValues = { email: '', password: '' };
  const dispatch = useDispatch();
  const loginError = useSelector(state => state.marketingSlice?.loginError);
  const loginData = useSelector(state => state.marketingSlice?.loginData);
  //const loading = useSelector(state => state.marketingSlice?.loading);
  const nav = useNavigate();

  //const dispatch = useDispatch()
  

  useEffect(() => {
    if (loginError) {
      message.error({
        content: loginError, // Pass the string directly
        className: "custom-class",
        style: {
          marginTop: "20vh",
          marginLeft: "40vh",
        },
      });
    }
  }, [loginError]);

  useEffect(() => {
    if (loginData?.login?.accessToken) {
      localStorage.setItem("authToken", loginData.login.accessToken);
      localStorage.setItem("refreshToken", loginData.login.refreshToken);
      nav("/");
    }
  }, [loginData, nav]);

  const handleSubmit = (values) => {
    dispatch(clearLoginError());
    const deviceId = "1";
    const email = values.email;
    const password = values.password;
    dispatch(getLogin(email, password, deviceId));
  };

  return (
    <>
      <CssBaseline />
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundImage: `url(${Background})`, // using a placeholder image for testing
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Card sx={{ minWidth: 275, padding: 3, position: 'absolute', top: '148px', right: '100px', width: '28%', height: '65%',borderRadius:"16px" }}>
          <CardContent>
            <Typography sx={{ fontWeight: '600', fontSize: '28px' }}>
              Sign In
            </Typography>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched }) => (
                <Form>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <div style={{ marginTop: '10px' }}>
                      <Typography variant="subtitle1" gutterBottom>
                        Email Address
                      </Typography>
                      <Field
                        as={TextField}
                        name="email"
                        variant="outlined"
                        fullWidth
                        error={touched.email && !!errors.email}
                        helperText={<ErrorMessage name="email" />}
                      />
                    </div>
                    <div style={{ marginTop: '10px' }}>
                      <Typography variant="subtitle1" gutterBottom>
                        Password
                      </Typography>
                      <Field
                        as={TextField}
                        name="password"
                        placeholder="Enter Your Password"
                        type={showPassword ? 'text' : 'password'}
                        variant="outlined"
                        fullWidth
                        error={touched.password && !!errors.password}
                        helperText={<ErrorMessage name="password" />}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <div style={{ marginTop: '20px' }}>
                      <Button type="submit" variant="contained" color="primary" sx={{width:"350px",height:"50px"}}>
                        Log In
                      </Button>
                    </div>
                  </Box>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}

export default Login;
