import React, { useEffect } from 'react';
import './DonutChart.css';

export default function CustomToolTip({ active, payload, label ,totalPercentage}) {
  
    return (
      <div className="custom-tooltip">
        {payload.map((pld) => (
          <div>
            
            <div style={{fontFamily:"Roboto",fontWeight:"700",fontSize:"14px"}}>{(pld.value / totalPercentage * 100).toFixed(2)}%</div>
        
           
          </div>
        ))}
      </div>
    );
}

  

