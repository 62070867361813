import React, { useEffect, useState } from "react";
import {
  Divider,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
  DialogContent,
  DialogActions,
  Dialog,
} from "@mui/material";
import Line from "../../Utils/Images/Line.svg";
import { contentFontSize_16, contentFontSize_16_xs_10 } from "../Typography";
import Rectangle from "../../Utils/Images/Rectangle.svg";
import Edit from "../../Utils/Images/Edit.svg";
import Delete from "../../Utils/Images/Delete.svg";
import TemplateForm from "./TemplateForm";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useNavigate } from "react-router-dom";
import CustomCard from "../Commoncomponents/card";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../Commoncomponents/button";
import { getTemplateDelete, getTemplateList } from "../../graphql/actions";
import { message } from "antd";
import { setDeleteTemplateSuccess } from "../../redux/slices/marketingSlice";
import CustomDialog from "../Commoncomponents/dialog";
export default function Template() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const uplgmatch = useMediaQuery(theme.breakpoints.up("lg"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  const steps = ["Select master ", "Create an ad", "Create an ad1"];
  const [form, setForm] = useState(false);
  const nav = useNavigate();
  function handleCreate() {
    //setForm(true)
    localStorage.removeItem("formValues");
    nav("/template/templateForm");
  }

  const smMatch = useMediaQuery(theme.breakpoints.down("sm"));
  const mdMatch = useMediaQuery(theme.breakpoints.down("md"));
  const lgMatch = useMediaQuery(theme.breakpoints.down("lg"));
  const xlMatch = useMediaQuery(theme.breakpoints.up("xl"));
  const isLessThan350 = useMediaQuery("(max-width:350px)");
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    dispatch(getTemplateList()); 
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dispatch = useDispatch();
  const listData = useSelector((state) => state.marketingSlice?.listData);
  console.log("data", listData?.listTemplates);
  const deleteData = useSelector((state) => state.marketingSlice?.deleteData);
  const [deletes, setDeletes] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null); // For tracking the item to delete

  const handleDialog = (id) => {
    setSelectedItemId(id); // Set the ID of the item you want to delete
    setDeletes(true); // Open the dialog
  };
  function handleClose() {
    setDeletes(false);
  }
  function handleDelete(id) {
    dispatch(getTemplateDelete(id));

    setDeletes(false);
  }
  const deleteTemplateSuccess = useSelector(
    (state) => state.marketingSlice.deleteTemplateSuccess
  );
  console.log("success", deleteTemplateSuccess);

  useEffect(() => {
    if (deleteTemplateSuccess) {
      setDeletes(false);
      message.success({
        content: "Template Deleted Successfully",
        className: "custom-success-message",
        style: {
          marginTop: "10vh",
          marginLeft: "15vw",
        },
        duration: 1.5,
      });

      setTimeout(() => {
        // Reload the list or perform any other actions
        dispatch(setDeleteTemplateSuccess());
        dispatch(getTemplateList());
        // Reset the success flag after handling it
      }, 1500);
    }
  }, [deleteTemplateSuccess]);

  console.log("deleteData", deleteData);

  return (
    <>
      <div>
        <Typography
          sx={{
            fontWeight: "600",
            fontFamily: "Inter",
            fontSize: "28px",
            marginBottom: "30px",
          }}
        >
          Template
        </Typography>
      </div>
      <div>
        <div
          style={{
            display: "flex",
            minHeight: "150vh",
            border: "1px solid #ccc",
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 25px 5px",
            borderRadius: "8px",
            overflow: "hidden",
            position: "relative",
          }}
        >
          {/* Add pseudo-element for horizontal line */}
          <div
            style={{
              position: "absolute",
              content: '""',
              top: "150px",
              left: 0,
              right: 0,
              borderBottom: "1px solid #7C7B7B",
              zIndex: 1,
            }}
          />

          <div
            style={{
              width: "30%",
              backgroundColor: "white",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
              position: "relative",
              zIndex: 0,
            }}
          >
            {/* Left content */}
            <Typography
              sx={{
                fontWeight: "600",
                fontFamily: "Inter",
                fontSize: { xs: "14px", sm: "16px", md: "20px", lg: "22px" },
                textAlign: "center",
                marginTop: "40px",
              }}
            >
              Available Template
            </Typography>

            {/* Add button */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleCreate}
                sx={{
                  width: smMatch ? "60%" : mdMatch ? "130px" : "300px",
                  marginTop: isLessThan350 ? "30px" : "120px",
                  textAlign: "center",
                  textTransform: "capitalize",
                  height: "50px",
                  borderRadius: "10px",
                }}
              >
                Create New Template
              </Button>
            </div>
            {/* <div
        style={{
          display: 'flex',
          flexDirection: mdMatch ? 'column' : 'row',
          justifyContent: mdMatch ? 'center' : 'space-evenly',
          marginTop: '20px',
          alignItems: 'center',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <CustomCard width="30px" height="40px" />
          <Typography style={{ textAlign: 'center', marginTop: '10px' }}>Welcome</Typography>
        </div>
        <div style={{ marginTop: mdMatch ? '20px' : '0', display: 'flex', alignItems: 'center' }}>
          <img src={Edit} style={{ cursor: 'pointer' }} />
          <img src={Delete} style={{ cursor: 'pointer', marginLeft: '20px' }} />
        </div>
      </div> */}
            {listData?.listTemplates?.map((item) => (
              <div
                key={item.id}
                style={{
                  display: "flex",
                  flexDirection: lgMatch ? "column" : "row",
                  justifyContent: mdMatch ? "center" : "space-evenly",
                  marginTop: "20px",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginLeft: "40px",
                  }}
                >
                  <>
                    <CustomCard
                      width="170px"
                      height="100%"
                      padding="10px 30px 80px 10px"
                    >
                      <div>
                        <Typography>{item?.header?.content}</Typography>
                      </div>
                      <div>
                        <Typography>{item?.body}</Typography>
                      </div>
                      <div style={{ marginTop: "15px" }}>
                        <CustomButton background="#0D75BA">
                          <Typography style={{ textTransform: "capitalize" }}>
                            {item.button}
                          </Typography>
                        </CustomButton>
                      </div>
                      <div style={{ marginTop: "15px" }}>
                        <Typography>{item.footer}</Typography>
                      </div>
                    </CustomCard>

                    <Typography
                      style={{ textAlign: "center", marginTop: "10px" }}
                    >
                      {item.templateName}
                    </Typography>
                  </>
                </div>
                <div
                  style={{
                    marginTop: lgMatch ? "20px" : "0",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={Edit}
                    style={{ cursor: "pointer" }}
                    //onClick={() => handleEdit(item.id)} // Pass the id onClick
                  />
                  <img
                    src={Delete}
                    style={{ cursor: "pointer", marginLeft: "20px" }}
                    onClick={() => handleDialog(item.id)} // Open the dialog with item ID
                  />
                </div>
              </div>
            ))}
          </div>
          {/*  */}
          {deletes && selectedItemId && (
            // <CustomDialog
            //   open={deletes}
            //   onClose={handleClose}
            //   top="20px"
            //   right="20px"
            //   width="400px"
            //   height="600px"
            //   borderRadius="25px"
            //   BackdropProps={{
            //     style: {
            //       backgroundColor: "rgba(0, 0, 0, 0.2)", // Adjust the opacity or color here
            //     },
            //   }}
            // >
            //   <DialogContent>
            //     <Typography
            //       sx={{ color: "black", fontWeight: "400", fontSize: "18px" }}
            //     >
            //       Are you sure you want to delete this Template?
            //     </Typography>
            //   </DialogContent>
            //   <DialogActions>
            //     <div
            //       style={{
            //         display: "flex",
            //         justifyContent: "center",
            //         alignItems: "center",
            //         marginRight: "70px",
            //         gap: "60px",
            //       }}
            //     >
            //       <div>
            //         <CustomButton
            //           onClick={() => handleDelete(selectedItemId)} // Delete the selected item
            //           color="white"
            //           background="#0D75BA"
            //         >
            //           Yes
            //         </CustomButton>
            //       </div>
            //       <div>
            //         <CustomButton
            //           onClick={handleClose}
            //           color="white"
            //           background="#0D75BA"
            //         >
            //           No
            //         </CustomButton>
            //       </div>
            //     </div>
            //   </DialogActions>
            // </CustomDialog>
            <Dialog
          open={deletes}
        >
          <DialogContent>
            <div style={{ height: "100%", width: "100%", padding: matches ? "10px" : "15px" }}>
              <div style={{ display: "flex", gap: "10px", flexDirection: "column" }}>
                <Typography fontSize={contentFontSize_16_xs_10} className='chatFontHeebo400Color'>Are you sure you want to delete this Template?</Typography>

                <div style={{ display: "flex", gap: matches ? "10px" : "15px", textAlign: "center", justifyContent: "space-evenly" }}>
                  <Typography fontSize={contentFontSize_16_xs_10} onClick={handleClose} className='chatFontHeebo400Color' style={{ cursor: "pointer" }}>Cancel</Typography>
                  <Typography fontSize={contentFontSize_16_xs_10}>|</Typography>
                  <Typography fontSize={contentFontSize_16_xs_10} onClick={() => handleDelete(selectedItemId)}  className='chatFontHeebo400Red' style={{ cursor: "pointer" }}>Delete</Typography>
                </div>

              </div>

            </div>
          </DialogContent>
        </Dialog>
          )}
        </div>
      </div>
    </>
  );
}
