import React from "react";
import TotalStores from "./TotalStores";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import CustomCard from "../Commoncomponents/card/index";
import TotalInvitataion from "./TotalInvitation";
import MyChartComponent from "./MyChartComponent";
import { HeaderNavigationFontSize_20 } from "../Typography";
import DonutChart from "./DonutChart";
import './Dashboard.css';
//import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
export default function Dashboard() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true); 
  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const uplgmatch = useMediaQuery(theme.breakpoints.up("lg"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <div style={{paddingLeft:'25px',paddingRight:"25px"}}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={6}
            sm={4}
            md={4}
            lg={4}
            xl={2.4}
            style={{ paddingLeft: "0px" }}
          >
            <CustomCard
              padding={smmatch ? "10px 5px" : "25px"}
              
              boxShadow="none"
              border="0.52px solid #A69797"
            >
              <TotalStores
                upXlmatch={upXlmatch}
                xlmatch={xlmatch}
                lgmatch={lgmatch}
                mdmatch={mdmatch}
                smmatch={smmatch}
                xsmatch={xsmatch}
              />
            </CustomCard>
          </Grid>
          <Grid item xs={6} sm={4} md={4} lg={4} xl={2.4}>
            <CustomCard
              padding={smmatch ? "10px 5px" : "25px"}
               border="0.52px solid #A69797"
               boxShadow="none"
            >
              <TotalInvitataion
                upXlmatch={upXlmatch}
                xlmatch={xlmatch}
                lgmatch={lgmatch}
                mdmatch={mdmatch}
                smmatch={smmatch}
                xsmatch={xsmatch}
              />
            </CustomCard>
          </Grid>
          <Grid item xs={6} sm={4} md={4} lg={4} xl={2.4}>
            <CustomCard
              padding={smmatch ? "10px 5px" : "25px"}
               border="0.52px solid #A69797"
               boxShadow="none"
            >
              <TotalInvitataion
                upXlmatch={upXlmatch}
                xlmatch={xlmatch}
                lgmatch={lgmatch}
                mdmatch={mdmatch}
                smmatch={smmatch}
                xsmatch={xsmatch}
              />
            </CustomCard>
          </Grid>
          <Grid item xs={6} sm={4} md={4} lg={4} xl={2.4}>
            <CustomCard
              padding={smmatch ? "10px 5px" : "25px"}
              border="0.52px solid #A69797"
               boxShadow="none"
            >
              <TotalInvitataion
                upXlmatch={upXlmatch}
                xlmatch={xlmatch}
                lgmatch={lgmatch}
                mdmatch={mdmatch}
                smmatch={smmatch}
                xsmatch={xsmatch}
              />
            </CustomCard>
          </Grid>
          <Grid item xs={6} sm={4} md={4} lg={4} xl={2.4}>
            <CustomCard
              padding={smmatch ? "10px 5px" : "25px"}
              border="0.52px solid #A69797"
               boxShadow="none"
            >
              <TotalInvitataion
                upXlmatch={upXlmatch}
                xlmatch={xlmatch}
                lgmatch={lgmatch}
                mdmatch={mdmatch}
                smmatch={smmatch}
                xsmatch={xsmatch}
              />
            </CustomCard>
          </Grid>
        </Grid>
      </Box>
      <div style={{ marginTop: "15px" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} style={{paddingLeft:"0px"}}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{paddingLeft:"0px"}}>
              
                <CustomCard padding="20px" height={upXlmatch?"430px":'370px'} boxShadow="none" background="#F4F4F4"  >
                
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      height:"100%"
                    }}
                  >
                
                    <Typography
                      fontSize={HeaderNavigationFontSize_20}
                      sx={{fontFamily:"Inter",fontWeight:"500"}}
                    >
                     Last week invitation
                    </Typography>
                    <div style={{height:"100%"}}>
                      <MyChartComponent 
                       upXlmatch={upXlmatch}
                       xlmatch={xlmatch}
                       lgmatch={lgmatch}
                       mdmatch={mdmatch}
                       uplgmatch={uplgmatch}
                       smmatch={smmatch}
                       xsmatch={xsmatch}
                       />
                    </div>
                   
                  </div>
                </CustomCard>
           
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{paddingLeft:smmatch&&"0px"}}>
             
                <CustomCard padding={uplgmatch?"20px 20px 30px 20px":"20px"} height={upXlmatch?"430px":'370px'} boxShadow="none" background="#F4F4F4">
               
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      height:"100%"
                    }}
                  >
                   
                    <Typography
                      fontSize={HeaderNavigationFontSize_20}
                      className="dashboardChartFontFamily"
                      sx={{fontFamily:"Inter",fontWeight:"500"}}
                    >Seller  Status
                    </Typography>
                    {/* <div style={{ height: "100%" }}> */}
                      <DonutChart
                        upXlmatch={upXlmatch}
                        xlmatch={xlmatch}
                        lgmatch={lgmatch}
                        mdmatch={mdmatch}
                        smmatch={smmatch}
                        xsmatch={xsmatch}
                        uplgmatch={uplgmatch}
                        
                      />
                   
                    {/* </div> */}
                  </div>
                </CustomCard>
             
            </Grid>
          </Grid>
        </Box>
      </div>
      <div style={{marginTop:"15px",marginLeft:"-15px"}}>
         <CustomCard>
          <div>
            <Typography sx={{color:"#0D75BA",textTransform:"uppercase",fontWeight:"400"}}>
              Activity Logs
            </Typography>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
  <div style={{ width: "0px", height: "50px", borderLeft: "5px solid #0D75BA", borderStartStartRadius: "8px", borderBottomLeftRadius: "8px" }}></div>
  <Box sx={{ width: '100%', marginTop: '14px',marginLeft:"8px" }}>
    <Collapse in={open}>
      <Alert
      icon={<span style={{ fontSize: '5.5rem', lineHeight: '1.5rem',color:"#0D75BA80" }}>•</span>}
        action={
          <IconButton
            aria-label="close"
            color="#0D75BA"
            size="small"
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon fontSize="inherit" style={{ color: '#0D75BA' }} />
          </IconButton>
        }
        sx={{ mb: 2,bgcolor:"#0D75BA26",color:"#0D75BA" }}
      >
        Click the close icon to see the Collapse transition in action!
      </Alert>
    </Collapse>
    {/* <Button
      disabled={open}
      variant="outlined"
      onClick={() => {
        setOpen(true);
      }}
    >
      Re-open
    </Button> */}
  </Box>
</div>

         </CustomCard>
      </div>
    </div>

  );
}
