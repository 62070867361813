import { configureStore } from '@reduxjs/toolkit';
import marketingSlice from './slices/marketingSlice';

const store = configureStore({
  reducer: {
    marketingSlice,
  },
});

export default store;
