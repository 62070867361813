import { Dialog, DialogContent, Typography, Divider, TextField, useMediaQuery, useTheme, Box, Select, FormControl, MenuItem, Checkbox } from '../MUIcomponents/index'
import React, { useEffect, useState } from 'react'
import { contentFontSize_20, contentFontsize_30, contentFontSize_16, contentFontSize_18 } from '../Typography'
import deleteFilter from '../../Utils/Images/deleteFilter.svg';
import copyFilter from '../../Utils/Images/copyFilter.svg';
import CustomButton from '../Commoncomponents/button';
import { useDispatch,useSelector} from 'react-redux';
import {filterStoreDetails,filterSave,filterStoreList, getFilterDelete} from '../../graphql/actions'
import {setClearSearchData,setClearSaveFilterData,setClearFilterAck,setFilterStoreBoolean} from '../../redux/slices/marketingSlice'
import { message } from 'antd';
import { styled } from '@mui/material/styles';
export default function Filter({ open1, handleClose1,setAge }) {
    const [select10, setSelect10] = useState('')
    const [select11, setSelect11] = useState('')
    const [select12, setSelect12] = useState('')
    const [checkedItems, setCheckedItems] = useState([]);
    const [input,setInput]=useState('')

    console.log("fitler array check",checkedItems)

    const handleResetFilter=()=>{
        setInput('')
        setInput2('')
        setSelect10('')
        setSelect11('')
        setSelect12('')
        setCheckedItems({})
    }

    const dispatch=useDispatch();
    
    const { filterSaveData,filterStoreError,filterStoreData,filterStoreBoolean} = useSelector((state) => state.marketingSlice);

   
    useEffect(()=>{
        if(filterStoreBoolean){
                handleClose1()
                dispatch(setFilterStoreBoolean())
        }
       else if(filterStoreError){
           
            message.error({
                content: filterStoreError?.payload?.message,
                className: "custom-class",
                style: {
                  marginTop: "10vh",
                  marginLeft: "15vw",
                },
                duration: 3.5,
              });
            setTimeout(() => {
                dispatch(setClearFilterAck())
            }, 500);
        }
        
            
        
    },[filterStoreError,filterStoreBoolean])

    useEffect(()=>{
        if(filterSaveData){
            message.success({
                content: "Filter saved successfully",
                className: "custom-success-message",
                style: {
                  marginTop: "10vh",
                  marginLeft: "15vw",
                },
                duration: 3.5,
              });
              setTimeout(()=>{
                dispatch(filterStoreList())
                dispatch(setClearSaveFilterData())
              },500)
            
              handleClose1()
        }
      },[filterSaveData])
      

    const handleChange = (id,label) => (event) => {
        setCheckedItems({
            ...checkedItems,
            [label]: event.target.checked,
        });
    };

    const handleChange10 = (event) => {
        setSelect10(event.target.value)
    }
    const handleChange11 = (event) => {
        setSelect11(event.target.value)
    }
    const handleChange12 = (event) => {
        setSelect12(event.target.value)
    }

    const handleFilterStore=()=>{
        const selectedKeys = Object.keys(checkedItems);
        dispatch(setClearSearchData())
        dispatch(filterStoreDetails(input,input2,select10,select11,select12,selectedKeys))
        setAge('')
       // handleClose1()
    }
    //const [input1,setInput1] = useState()
    const [input2,setInput2] = useState()
    const handleInputChange=(e)=>{
        setInput(e.target.value)
    }
    
    const handleInputChange2=(e)=>{
        setInput2(e.target.value)
    }
    const handleSaveFilter=()=>{
        if(input===''){
            message.error({
                content: "please Enter Filter Name",
                className: "custom-class",
                style: {
                  marginTop: "10vh",
                  marginLeft: "15vw",
                },
                duration: 3.5,
              });
        }else{
            console.log("dummy")
            const selectedKeys = Object.keys(checkedItems);
            dispatch(filterSave(input,input2,select10,select11,select12,selectedKeys))
        }
    }
   console.log("input",input)
   //console.log("input1",input2)
   console.log("input2",input2)
   console.log("select10",select10)
   console.log("select11",select11)
   console.log("select12",select12)
  // console.log(sele)
    // const handleInputChange=(e)=>{
    //     setInput(e.target.value)
    // }
    // const [input1,setInput1] = useState()
    // const [input2,setInput2] = useState()
    // const handleInputChange2=(e)=>{
    //     setInput2(e.target.value)
    // }
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("md"));
    const smmatch = useMediaQuery(theme.breakpoints.down("sm"))
    const upLgmatch = useMediaQuery(theme.breakpoints.up("xl"));


    const stateFilterStore = [
        { id: 1, label: "Tamil Nadu" },
        { id: 2, label: "kerala" },
        { id: 3, label: "Telugana" }
    ]
    const districtFilterStore = [
        { id: 1, label: "Kanyakumari" },
        { id: 2, label: "Trunelveli" },
        { id: 3, label: "Chennai" }
    ]
    const cityFilterStore = [
        { id: 1, label: "Paloor" },
        { id: 2, label: "karungal" },
        { id: 3, label: "Nagarcoil" }
    ]

    const rows = [
        { id: 1, label: "Restaurants" },
        { id: 2, label: "Pet Store" },
        { id: 3, label: "Fish & Meat Store" },
        { id: 4, label: "Supermarket" },
        
        { id: 5, label: "Bakery" },
        { id: 6, label: "Grocery Store" },
        { id: 7, label: "Home Business" },
        { id: 8, label: "Electronics" },
        { id: 9, label: "Pharmacy" },
        { id: 10, label: "Crafts" },
        { id: 11, label: "Stationary" },
        {id:12,label:"Beauty"}
        

    ]
    // const CustomCheckbox = styled(Checkbox)({
    //     color: 'blue',  // Set the default color for the border
    //     '&.Mui-checked': {
    //       color: 'blue',  // Color when the checkbox is checked
    //     },
    //     '& .MuiSvgIcon-root': {
    //       fontSize: 28,  // Adjust size
    //       stroke: 'blue',  // Stroke color (border)
    //       strokeWidth: .5,  // Adjust stroke width for border
    //     },
    //   });


    function handleFilterDelete(){
        dispatch(getFilterDelete())
    }
    
    return (
        <div>
            <Dialog
                open={open1}
                onClose={handleClose1}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="900"
            >
                <DialogContent>

                    <Typography style={{ fontFamily: "Heebo", fontWeight: "400" }} fontSize={contentFontsize_30}>
                        Filter
                    </Typography>
                    <div style={{ margin: "20px 0px" }}>
                        <Divider />
                    </div>
                   
                    <div style={{ marginTop: "30px", display: 'flex', gap: "20px", flexWrap: smmatch && "wrap" }}>
                        <TextField
                            variant="outlined"
                            placeholder='Type or select filter name'
                            sx={{
                                width: '100%',

                                '& .MuiOutlinedInput-root': {
                                    borderRadius: "10px",
                                    '& fieldset': {
                                        borderColor: '#0D75BA', // This will change the border color
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#0D75BA', // This will change the border color when focused
                                    },
                                },

                                '& .MuiInputBase-input': {
                                    backgroundColor: 'white',

                                },
                            }}
                            InputProps={{
                                sx: {
                                    fontSize: matches && '13px',
                                    height: matches ? "36x" : "45px",

                                },


                            }}
                            size={matches && "small"}
                            name="Search"
                            value={input}
                            onChange={handleInputChange}

                        />
                        <div style={{ height: "auto", width: "50px", padding: matches ? "5px" : "10px", borderRadius: "8px", background: "#0D75BA", display: "flex", alignItems: "center", justifyContent: "center",cursor:"pointer" }} onClick={handleSaveFilter}>
                            <img src={copyFilter} alt='copy filter' width={matches ? "20px" : "25px"} height={matches ? "20px" : "25px"} />
                        </div>
                        <div style={{ height: "auto", width: "50px", padding: matches ? "5px" : "10px", borderRadius: "8px", background: "#0D75BA", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <img src={deleteFilter} alt='delete filter' width={matches ? "20px" : "25px"} height={matches ? "20px" : "25px"}  onClick={handleFilterDelete}/>
                        </div>
                    </div>
                    <div style={{marginTop:"30px"}}>
                    <TextField
                            variant="outlined"
                            placeholder='store name'
                            sx={{
                                width: '100%',

                                '& .MuiOutlinedInput-root': {
                                    borderRadius: "10px",
                                    '& fieldset': {
                                        borderColor: '#0D75BA', // This will change the border color
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#0D75BA', // This will change the border color when focused
                                    },
                                },

                                '& .MuiInputBase-input': {
                                    backgroundColor: 'white',

                                },
                            }}
                            InputProps={{
                                sx: {
                                    fontSize: matches && '13px',
                                    height: matches ? "36x" : "45px",

                                },


                            }}
                            size={matches && "small"}
                            name="Search"
                            value={input2}
                            onChange={handleInputChange2}

                        />
                    </div>
                    <div style={{ margin: "20px 0px" }}>
                        <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
                            <div style={{ display: "flex", flexDirection: "column", gap: "15px", width: smmatch && "100%" }}>
                                <Typography style={{ fontFamily: "Heebo", fontWeight: "400" }} fontSize={contentFontSize_20}>State</Typography>
                                <Box sx={{ minWidth: smmatch ? 1 : 200 }}>
                                    <FormControl fullWidth>

                                        <Select
                                            sx={{
                                                borderRadius: "10px",
                                                height: matches ? "36px" : "42px",
                                                width: "100%",
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#0D75BA',

                                                },
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#0D75BA',
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#0D75BA',
                                                },
                                            }}
                                            displayEmpty

                                            value={select10}

                                            onChange={handleChange10}
                                            renderValue={(selected) => {

                                                if (!selected) {
                                                    return <Typography style={{ fontFamily: "Heebo", fontWeight: "400", color: "#818181" }} fontSize={contentFontSize_16}>Select</Typography>;
                                                }
                                                return <Typography style={{ fontFamily: "Heebo", fontWeight: "400" }} fontSize={contentFontSize_16}>{selected}</Typography>;
                                            }}

                                        >
                                            {stateFilterStore.map((item) => (
                                                <MenuItem key={item.id} value={item.label}>
                                                    <Typography style={{ fontFamily: "Heebo", fontWeight: "400" }} fontSize={contentFontSize_16}>
                                                        {item.label}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", gap: "15px", width: smmatch && "100%" }}>
                                <Typography style={{ fontFamily: "Heebo", fontWeight: "400" }} fontSize={contentFontSize_20}>District</Typography>
                                <Box sx={{ minWidth: smmatch ? 1 : 200 }}>
                                    <FormControl fullWidth>

                                        <Select
                                            sx={{
                                                borderRadius: "10px",
                                                height: matches ? "36px" : "42px",
                                                width: "100%",
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#0D75BA',

                                                },
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#0D75BA',
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#0D75BA',
                                                },
                                            }}
                                            displayEmpty

                                            value={select11}

                                            onChange={handleChange11}
                                            renderValue={(selected) => {

                                                if (!selected) {
                                                    return <Typography style={{ fontFamily: "Heebo", fontWeight: "400", color: "#818181" }} fontSize={contentFontSize_16}>Select</Typography>;
                                                }
                                                return <Typography style={{ fontFamily: "Heebo", fontWeight: "400" }} fontSize={contentFontSize_16}>{selected}</Typography>;
                                            }}

                                        >
                                            {districtFilterStore.map((item) => (
                                                <MenuItem key={item.id} value={item.label}>
                                                    <Typography style={{ fontFamily: "Heebo", fontWeight: "400" }} fontSize={contentFontSize_16}>
                                                        {item.label}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", gap: "15px", width: smmatch && "100%" }}>
                                <Typography style={{ fontFamily: "Heebo", fontWeight: "400" }} fontSize={contentFontSize_20}>City</Typography>
                                <Box sx={{ minWidth: smmatch ? 1 : 200 }}>
                                    <FormControl fullWidth>

                                        <Select
                                            sx={{
                                                borderRadius: "10px",
                                                height: matches ? "36px" : "42px",
                                                width: "100%",
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#0D75BA',

                                                },
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#0D75BA',
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#0D75BA',
                                                },
                                            }}
                                            displayEmpty

                                            value={select12}

                                            onChange={handleChange12}
                                            renderValue={(selected) => {

                                                if (!selected) {
                                                    return <Typography style={{ fontFamily: "Heebo", fontWeight: "400", color: "#818181" }} fontSize={contentFontSize_16}>Select</Typography>;
                                                }
                                                return <Typography style={{ fontFamily: "Heebo", fontWeight: "400" }} fontSize={contentFontSize_16}>{selected}</Typography>;
                                            }}

                                        >
                                            {cityFilterStore.map((item) => (
                                                <MenuItem key={item.id} value={item.label}>
                                                    <Typography style={{ fontFamily: "Heebo", fontWeight: "400" }} fontSize={contentFontSize_16}>
                                                        {item.label}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </div>
                        </div>

                    </div>

                    <div style={{ margin: "20px 0px" }}>
                        <Typography style={{ fontFamily: "Heebo", fontWeight: '400' }} fontSize={contentFontSize_20}>Business Type</Typography>
                        <div style={{ margin: "20px 0px", display: "grid", gap: "20px", gridTemplateColumns:  "repeat(auto-fit, minmax(120px, 1fr))" }}>
                            {rows.map((row) => (
                               
                                    <div key={row.id} style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                        {/* <Checkbox
  checked={checkedItems[row.label] || false}
  onChange={handleChange(row.id, row.label)}
  inputProps={{ 'aria-label': 'controlled' }}
  style={{ color: "#0D75BA",borderWidth:"0.5px"}}
  sx={{
    '& .MuiSvgIcon-root': { fontSize: 28 },
    
    
  }}
 
/> */}
<Checkbox
                    icon={
                      <Box
                        sx={{
                          width: 27,
                          height: 27,
                          border: "1px solid #0D75BA",
                          borderRadius: "0px",
                        }}
                      />
                    } 
                    checkedIcon={
                      <Box
                        sx={{
                          width: 27,
                          height: 27,
                          border: "1px solid #0D75BA",
                          borderRadius: "0px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#0D75BA", 
                        }}
                      >
                        <Box
                          sx={{
                            width: 18, // Adjust the width to make the tick more prominent
                            height: 9, // Increase height to match checkbox height better
                            borderLeft: "2px solid white", // Thicker line for the tick
                            borderBottom: "2px solid white", // Thicker line for the tick
                            transform: "rotate(-45deg)",
                          }}
                        />
                      </Box>
                    } 
                    checked={checkedItems[row.label] || false}
                    onChange={handleChange(row.id, row.label)}
                    sx={{
                      color: "white", 
                      "& .MuiSvgIcon-root": {
                        borderRadius: "4px", 
                      },
                    }}
                  />

                                        <Typography style={{fontFamily:"Heebo",fontWeight:"400",color:"#818181"}} fontSize={contentFontSize_18}>{row.label}</Typography>
                                    </div>
                              
                            ))}
                        </div>
                    </div>
                    <div style={{marginTop:"20px",display:"flex",justifyContent:"flex-end",gap:"10px"}}>
                    <CustomButton matchPadding={true} padding={matches ? "4px 40px" : "8px 50px"} background="#0D75BA" borderRadius="10px" onClick={handleResetFilter}><Typography style={{ textTransform: "capitalize", fontFamily: "Heebo", fontWeight: "500" }} >Reset</Typography> </CustomButton>
                <CustomButton matchPadding={true} padding={matches ? "4px 40px" : "8px 50px"} background="#0D75BA" borderRadius="10px" onClick={handleFilterStore}><Typography style={{ textTransform: "capitalize", fontFamily: "Heebo", fontWeight: "500" }} >Apply</Typography> </CustomButton>

                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}
